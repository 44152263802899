<template>
  <div id="privacyPolicy" style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll" align="center" overflow="scroll">
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div style="margin-top: 20px">
        <h3>
          <p style="font-size: 26px">{{ themeData.saasMerNameZh }}服务隐私政策</p>
        </h3>
      </div>
      <div align="left" style="margin-top: 50px">
        <div>
          <p>
            您的隐私对我们很重要。{{ themeData.saasMerNameZh }}公司有几条基本原则：<br>
            • 除非我们真正需要，否则我们不会要求您提供个人信息。<br>
            • 除了遵守法律，提供我们的服务或保护我们的权利，我们不会与任何人分享您的个人信息。<br>
            • 我们将访问您的个人信息限制在需要了解该信息以向您提供服务的员工和服务提供商。<br>
            • 我们维护物理的，电子的和流程上的安全协议，以保护您的个人信息。<br>
            本隐私政策以及《{{ themeData.saasMerNameZh }}服务协议》管理{{ themeData.saasMerNameZh }}收集、处理和使用您的个人信息的行为。本私隐政策所称“{{ themeData.saasMerNameZh }}”是指{{ themeData.saasMerNameZh }}集团公司，包括但不限于其所有者，董事，投资者，雇员或其他关联方。“个人资料”是指可识别个人的信息，如姓名，地址，电子邮件地址，交易信息和银行账户详细信息。“个人信息”不包括不标识特定用户的匿名和/或聚合信息。<br>
            通过订立本协议和条款，您将明确接受目前的{{ themeData.saasMerNameZh }}隐私政策，并同意收集，处理和传播您的信息，如本“{{ themeData.saasMerNameZh }}隐私政策”所述。<br>
            本隐私政策的目的是描述：<br>
            1. 我们收集的个人信息类型以及如何使用；<br>
            2. 我们如何使用浏览器缓存（Cookie）和类似技术；<br>
            3. 我们如何以及为何向第三方披露您的个人信息；<br>
            4. 您的个人信息在本国内外的转移；<br>
            5. 您访问，更正，更新和删除您的个人信息的权利；<br>
            6. 我们用于保护和防止个人信息丢失，滥用或更改的安全措施；<br>
            7. {{ themeData.saasMerNameZh }}对您的个人信息的保留。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">一、简介</p>
          <p>为了能够按照“适用法”和监管部门规定的方式向您提供{{ themeData.saasMerNameZh }}的服务，{{ themeData.saasMerNameZh }}必须向您询问有关您自己的某些信息，包括财务信息（例如，您的身份信息，电子商务销售，受益账户等）。 {{ themeData.saasMerNameZh }}将根据相应管辖区的信息保护法律规定利用您提供的信息。所获得的资料，原则上应仅在提供此类服务所需的范围内，由{{ themeData.saasMerNameZh }}单独用于提供{{ themeData.saasMerNameZh }}服务。除了我们有法律义务或由于法律条例规定或为了适当地提供服务，我们存储的关于您的信息原则上不得向第三方披露。</p>
        </div>
        <div>
          <p class="navigationTitle">
            二、我们如何收集信息
          </p>
          <p>
            A. 从网站访问者收集<br>
            像大多数网站运营商一样，{{ themeData.saasMerNameZh }}自动收集网页浏览器和服务器通常提供的非个人识别信息，例如浏览器类型，语言偏好，引用站点以及每个访问者请求的日期和时间。 {{ themeData.saasMerNameZh }}收集非个人识别信息的目的是为了更好地了解{{ themeData.saasMerNameZh }}的访问者如何使用其网站。我们通过cookies，网络信标，日志文件和其他技术收集：<br>
            （1）您的域名，您的浏览器类型和操作系统，您查看的网页，您点击的链接;<br>
            （2）您的IP地址，您访问我们网站或使用我们的服务的时间长短，您在我们网站上的活动，以及引导您访问我们网站的引用URL或网页。<br>
            请参阅下面第8节“Cookies”以获取更多信息。访客有权拒绝提供个人身份信息，并提醒他们可能会阻止他们使用{{ themeData.saasMerNameZh }}的服务。<br>
            B.用户提供<br>
            您可以通过填写我们网站上的表格（例如{{ themeData.saasMerNameZh }}帐户注册）或与我们联系（例如通过电子邮件或电话）向我们提供有关您的信息。您提供的信息可能包括有关{{ themeData.saasMerNameZh }}服务的受益所有者等其他人员的信息。这些提供信息给我们的方式包括：<br>
            （1）当您在{{ themeData.saasMerNameZh }}注册账户；<br>
            （2）当您登录到您的{{ themeData.saasMerNameZh }}帐户；<br>
            （3）通过您的{{ themeData.saasMerNameZh }}账户提交任何结算订单；<br>
            （4）参与比赛，促销或调查时；<br>
            （5）报告问题或支持请求时。<br>
            访客有权拒绝提供个人识别信息，但需要注意这可能会阻止他们使用{{ themeData.saasMerNameZh }}的服务。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">三、我们收集的信息</p>
          <p>当您申请{{ themeData.saasMerNameZh }}帐户，在{{ themeData.saasMerNameZh }}平台上执行任何交易或使用其他{{ themeData.saasMerNameZh }}服务时，我们会收集您提供的个人信息。这可能包括：<br>
            1. 您的联系信息（例如，姓名，电子邮件地址，电话号码，帐单或邮寄地址）<br>
            2. 银行和信用账户信息<br>
            3. IP地址<br>
            4. 身份验证（例如，照片，其他要求您提供以验证您身份的信息，包括有效身份证件副本）<br>
            5. 公开的犯罪史<br>
            6. 国民身份证号码<br>
            7. 国籍<br>
            8. 出生日期和地点<br>
            9. 使用任何服务进行的任何交易的详细信息<br>
            10. 您选择提供给我们的任何其他信息（例如，如果您向我们发送电子邮件/或联系我们）<br>
            11. 电话/电邮/其他通讯<br>
            12. 通过上面列出的Cookie和其他跟踪技术的信息，如下文“Cookies”所述<br>
            您有责任提供准确和最新的信息。<br>
            如果您需要提供有关您的业务的股东或实益拥有者的信息，您确认您有获得本人将其信息提供给我们的同意。这可能包括：<br>
            1. 联系信息，如姓名，住址，电子邮箱地址等。<br>
            2. 账号信息，如用户名和密码。<br>
            3. 财务信息，如银行账号，银行对账单和交易信息。<br>
            4. 身份验证信息，如您所在国政府颁发的身份证件，护照，国民身份证或驾驶执照。注：可能要求美国居民提供社会保险号码。<br>
            5. 住址验证信息，如实用的账单详情或类似信息。<br>
            为提供{{ themeData.saasMerNameZh }}服务，我们可能收集和处理与您的业务相关的买家或第三方提供的个人信息。您有责任确保任何第三方（包括与您的业务相关的买家和其他个人）的隐私权受到尊重，包括确保适当披露第三方信息的收集和使用；对于此类信息，您在此（i）被视为和（ii）接受为信息控制人。我们作为您的信息处理方，将根据我们的协议条款和您的合法指示处理个人信息。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">四、我们如何使用您的个人信息</p>
          <p>我们只会在下列情况下使用您的个人信息：<br>
            1. 处理您的交易，包括根据中国人民银行和/或国家外汇管理局的规定将资金转入中国；<br>
            2. 根据反洗钱/恐怖主义融资法律进行必要的控制和检查，并根据适用法和内部控制政策了解您的客户要求，并处理其他执法需求，这在我们的条款和条件中有更充分描述。<br>
            3. 分析{{ themeData.saasMerNameZh }}网站使用情况，改进网站和网站产品。<br>
            4. 帮助我们回应您的客户服务请求和支持需求。<br>
            5. 与您联系有关{{ themeData.saasMerNameZh }}服务。您提供的电子邮件地址可能用于传达与使用{{ themeData.saasMerNameZh }}服务相关的信息和更新。我们也可能偶尔传达与{{ themeData.saasMerNameZh }}类似产品和服务相关的公司新闻，更新，促销活动及相关信息。<br>
            6. 管理比赛、促销、调查或其他网站功能，这将在网站上进行更多的解释。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">五、披露个人信息</p>
          <p>{{ themeData.saasMerNameZh }}仅在以下情况下披露可能涉及个人识别的信息：（a）根据适用法的规定，法律和监管部门要求提供个人信息的情形；（b）对于其雇员，服务提供商和附属机构，（i）为了处理或提供服务需要知道该信息，以及（ii）已经同意不向其他人透露的情况下，如下所述。<br>
            A. 向第三方披露<br>
            在处理您的交易时，我们可能会与服务提供商分享一些您的个人信息，以帮助我们的开展业务。未经您的同意，您的信息将不会与任何第三方出售，交换或共享，除非为了提供{{ themeData.saasMerNameZh }}服务或法律要求。通过使用我们的服务并接受我们的条款和条件，您同意根据本隐私政策所述披露您的个人信息。<br>
            {{ themeData.saasMerNameZh }}的服务提供商在合同上有义务保护此类信息，以及仅用于其披露的目的使用，除非法律另有要求或允许。我们确保这些第三方受到比本隐私政策、或适用的信息保护法律的保护性条款更严格的约束，包括但不限于“适用法”规定的条款的约束。<br>
            B. 向法律机关披露<br>
            在以下情况下，我们可能会与执法部门，信息保护部门，政府和其他机构的分享您的个人信息：<br>
            （1）受传唤，法庭命令或其他法律程序的要求。<br>
            （2）我们认为披露是必要的，以防止实际损害或经济损失。<br>
            （3）披露涉嫌非法活动所必要的信息。<br>
            （4）披露有关违反本隐私政策或本使用条款的行为所必要的信息。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">六、传输和存储信息</p>
          <p>我们的服务是全球性的，信息可能在我们业务范围的任何国家/地区以及我们与服务提供商合作的地区进行存储和处理。我们收集的信息可能会转移到您的居住国或平台管辖区之外的目的地和/或存储在其中的目的地，这些管辖区可能具有与您所在国家不同的信息保护规则，包括向监管机构传输信息，或传输给在国外运营的、代表我们或我们的供应商处理信息的员工，员工可能从事履行您的要求并提供支持服务的业务。但是，我们将采取措施确保任何此类传输符合可适用的信息保护法律，并确保对您的信息保护符合本隐私政策中描述的标准。通过提交信息，即表示您同意转让，存储或处理。我们将采取合理必要的一切步骤，确保信息得到安全处理，并符合本隐私政策和相关信息保护规定。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">七、保护某些个人识别信息</p>
          <p>{{ themeData.saasMerNameZh }}采取适当的组织和技术措施，防止未经授权的访问，使用，更改或破坏潜在的身份识别和个人识别信息。<br>
            您提供给我们的所有信息都存储在我们的安全服务器上。您有责任保持帐户证书的安全，并且不与任何人共享。<br>
            通过互联网传播信息不是完全安全的，任何传输都由您自己承担风险。虽然没有人可以保证通过互联网传输的信息的安全性，但我们尽最大努力保护通过{{ themeData.saasMerNameZh }}帐户传输的信息安全。我们使用行业标准安全技术来帮助保持信息安全，包括信息传输和其他情形的加密。<br>
            {{ themeData.saasMerNameZh }}网站可能不定期推广我们的合作伙伴网络，广告客户和关联公司网站的链接。如果您链接到任何这些网站，请注意，这些网站有自己的隐私政策，我们不对这些政策承担任何责任。在向任何第三方提交任何用户信息之前，请先查看这些政策。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">八、Cookies</p>
          <p>为使本网站正常工作，{{ themeData.saasMerNameZh }}与许多其他主要网站类似，有时会使用称为Cookie或其他跟踪技术的小型信息文件来跟踪有关您使用我们网站和服务的信息。我们可能会使用第三方服务提供商代我们收集这些信息。<br>
            A. 什么是Cookies？<br>
            Cookie是一个小文本文件，网站在您访问该网站时会保存在您的计算机或移动设备上。它使网站能够在一段时间内记住您的操作和偏好（例如登录，语言，字体大小和其他显示首选项），因此，当您从一页浏览到另一页返回网站时不用重新登录。<br>
            B. 我们如何使用cookies和跟踪机制<br>
            {{ themeData.saasMerNameZh }}的网站使用以下cookies /跟踪机制：<br>
            • 会话Cookie。会话Cookie是在您离开网站之前保留在浏览器的cookie文件中的临时Cookie。我们使用会话cookie来允许您在我们网站的页面上传送信息，并避免重新提交相同的信息。网络浏览器关闭后，Cookie将被删除。<br>
            • 持久性Cookie。持久性Cookie仍然保留在浏览器的cookie文件中（更长时间取决于特定Cookie的周期）。我们在下列情形下使用持久性Cookie：<br>
            o 当您返回我们的网站时，帮助我们确认您是唯一的访问者，并监控您对我们网站的使用情况；<br>
            o 如果您通过附属机构或合作伙伴的网站上的付费广告或横幅来到我们的网站，允许我们将您链接到我们的任何合作伙伴。<br>
            您的网络浏览器关闭后，Cookie将根据其自身的失效日期自动删除。<br>
            • 网络信标。我们的一些网页可能包含网络信标，允许我们对访问过这些网页的用户进行统计。网络信标仅收集有限的信息，包括cookie号码，页面浏览的时间和日期以及Web信标所在的页面的描述。这些信标不带有任何个人识别信息，并用于跟踪特定营销活动的有效性。<br>
            C. 如何控制Cookies<br>
            您可以根据需要控制和/或删除Cookies。有关详细信息，请参阅aboutcookies.org。您可以删除计算机上已经存在的所有Cookie，并且大多数浏览器可以设置以防止它们被保留。但是，如果这样做，您可能必须在每次访问网站时手动调整某些首选项，并且某些服务和功能可能无法正常工作。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">九、隐私政策更改</p>
          <p>随着新功能被添加到{{ themeData.saasMerNameZh }}服务，或随着我们纳入利益相关者的建议，本隐私政策可能会随着时间的推移而修改。我们可能随时通过在我们的网站上发布其修订版本来更改本隐私政策。当法律有要求时，我们将根据法律要求至少提前30天通知修改后的隐私政策的生效日期。我们可能会在我们网站的首页上发布通知并/或通过电子邮件发送通知。自修订隐私政策的生效日期起，您将被视为同意对隐私政策的所有更改。如果您不同意本隐私政策的条款，您可以随时关闭您的帐户。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">十、法律责任</p>
          <p>对于符合本隐私权政策和其他已获得您同意的任何使用和/或披露或传播用户信息的行为，由此产生的任何索赔、损失、损害赔偿、费用（包括合理法律费用），我们概不负责。<br>
          </p>
        </div>
      </div>
    </a-card>
  </div>

</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
  name: 'PrivacyPolicyCN',
  setup () {
    const state = reactive({
      themeData: inject('$themeData')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.navigationTitle{margin-top: 20px;font-size: 18px;color: black;font-weight: bold;}
</style>
